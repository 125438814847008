

function PageNotFound ()
{
    return (
        <div>
            Trang khong thay
        </div>
    )
}

export default PageNotFound;