import Results from "./Results";
import SeachBar from "./SeachBar";

function Home(params) {
    return (
        <div>
            <SeachBar/>
            <Results/>
        </div>
    )
}

export default Home;